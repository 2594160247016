import React from 'react';

export default function FullWidthImage({ url, alt, customHeight, customWidth, className = "" }) {
    const style = {};
    if (customHeight) {
        style.height = customHeight;
    }
    if (customWidth) {
        style.width = customWidth;
    }

    return (
        <div className="relative w-full" style={style}>
            <img
                src={url}
                alt={alt}
                className={`object-cover object-center w-full h-full ${className}`}
            />
        </div>
    );
}
