import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL + "api/public/";

export const Api = {
    getProducts: async function () {
        try {
            return await axios.get(API_URL + 'products/');

        } catch (error) {
            console.log(error);
        }
    },

    getProduct: async function (slug) {
        try {
            return await axios.get(API_URL + 'products/getProduct/' + slug);

        } catch (error) {
            console.log(error);
        }
    },

    getCart: async function (cart) {
        try {
            return await axios.get(API_URL + 'products/getCart', {
                params: { cart }
            });

        } catch (error) {
            console.log(error);
        }
    },

    updateCart: async function (slug) {
        try {
            return await axios.get(API_URL + 'products/' + slug);

        } catch (error) {
            console.log(error);
        }
    }
}

export default Api;