import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

import Api from '../../services/api.service';
// import H1 from '../../components/Titles/h1';
// import Link from '../../components/Links/link';

import Loading from '../../components/Loading/loading';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import FullWidthImage from '../../components/Images/image-full-width';

import Button from '../../components/Buttons/button';
// import DescriptionSplit from '../../components/Description/description-split';
import TitlePage from '../../components/title-page';
// import Select from '../../components/Inputs/select';
// import H4 from '../../components/Titles/h4';
// import H5 from '../../components/Titles/h5';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import H2 from '../../components/Titles/h2';
// import H3 from '../../components/Titles/h3';

import Hr from '../../components/Style/hr';
import { useCart } from '../../contexts/cart-context';
import CardProductCart from './card-product-cart';

export default function Cart() {
    const { cart } = useCart();

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        console.log(cart);

        if (cart.length) {
            Api.getCart(cart).then((response) => {
                const res = response.data;
                if (res.status) {
                    console.log(res);
                    setProducts(res.data);
                    setLoading(false);
                } else {
                    console.log("error");
                }
            });
        }
    }, [cart]);

    return (
        <div className='bg-backgroundColor'>
            {<Helmet>
                <title>L'Artbanne - {t('cart.pageTitle')}</title>
            </Helmet>}

            <div className='m-auto max-w-6xl w-5/6 pt-14'>
                <TitlePage title={t('cart.welcomeMessage')} description={t('cart.description')} />

                <div className='my-12'>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className='flex gap-12'>
                                <div className='bg-white rounded-xl shadow p-4 w-full'>
                                    <H2 title={t('cart.myCart')} theme='main' fontWeight='semibold' />
                                    <Hr />

                                    {products.map((product, index) => (
                                        <>
                                            <div key={product.slug}>
                                                <CardProductCart product={product} />
                                            </div>
                                            {index !== products.length - 1 && <Hr />}
                                        </>
                                    ))}
                                </div>

                                <div className='bg-white rounded-xl shadow p-4 h-fit'>
                                    <H2 title={t('cart.total')} theme='main' fontWeight='semibold' />
                                    <Hr />
                                    <div className='flex justify-between text-gray font-light text-lg md:text-2xl'>
                                        <span>{t('cart.subtotal')}</span>
                                        <span>€€€</span>
                                    </div>
                                    <Hr />
                                    <div className='flex justify-between text-gray font-light text-base md:text-xl'>
                                        <span>{t('cart.shippingCost')} :</span>
                                        <span>€€€</span>
                                    </div>

                                    <div className='flex justify-between text-gray font-light text-sm md:text-base mt-2'>
                                        <span>Vins :</span>
                                        <span>€€</span>
                                    </div>

                                    <div className='text-gray font-light text-xs mt-2'>
                                        <span>{t('cart.shippingCostMayVary')}</span>
                                    </div>

                                    <Hr />

                                    <div>
                                        <div className='flex justify-between text-black font-bold text-lg md:text-2xl'>
                                            <span>{t('cart.total')} :</span>
                                            <span>€€€</span>
                                        </div>
                                    </div>

                                    <div className='w-full text-center m-auto pt-8'>
                                        <Button text={t('cart.paiement')} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}