import H2 from './Titles/h2';
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Hr from './Style/hr';
import ButtonSecondary from './Buttons/button-secondary';
import { useTranslation } from 'react-i18next';
import Link from './Links/link';
import { useCart } from './../contexts/cart-context';

export default function TitlePage({ title, description }) {
    const { cart } = useCart();

    const { t } = useTranslation();

    const cartProducts = () => {
        let items = 0;
        for (const product of cart) {
            items += Number(product.quantity);
        }
        return items;
    };

    // console.log(cart);
    return (
        <>
            <H2 title={title} fontWeight='bold' />

            <div className='flex mt-5'>
                <p className='w-3/4 text-description opacity-60'>
                    {description}
                </p>
                <div className='w-1/4 flex items-end justify-end'>
                    <Link link="/cart">
                        <div className='relative'>
                            <ButtonSecondary text={t('cart.cart')} icon={faCartShopping} />
                            {cartProducts() > 0 &&
                                <span className='absolute -top-3 -right-3 rounded-full bg-main text-white px-2 pt-1 pb-0.5 text-xs font-bold'>
                                    {cartProducts()}
                                </span>
                            }
                        </div>
                    </Link>
                </div>
            </div >

            <Hr />
        </>
    )
};