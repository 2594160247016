import React, { createContext, useContext, useEffect, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false); // New state to track if the cart has been loaded

    // Load cart data from local storage on component mount
    useEffect(() => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
        setIsLoaded(true); // Set isLoaded to true after loading cart
    }, []);

    // Save cart data to local storage whenever the cart changes
    useEffect(() => {
        if (isLoaded) { // Only run if the cart has been loaded
            console.log('cart change');
            console.log(cart);
            localStorage.setItem('cart', JSON.stringify(cart));
        }
    }, [cart, isLoaded]); // Add isLoaded as a dependency


    // const addToCart = (product) => {
    //     console.log(product);
    //     setCart((prevCart) => {
    //         cartUpdated.current = true; // Set the flag to true when cart is updated
    //         return [...prevCart, product];
    //     });
    // };


    const addToCart = (product) => {
        setCart((prevCart) => {
            // Check if the product already exists in the cart
            const existingProductIndex = prevCart.findIndex(item => item.slug === product.slug && item.reference === product.reference);

            //TODO check quantity max

            if (existingProductIndex !== -1) {
                // If the product exists, update its quantity
                const updatedCart = [...prevCart];
                updatedCart[existingProductIndex] = {
                    ...updatedCart[existingProductIndex],
                    quantity: Number(updatedCart[existingProductIndex].quantity) + Number(product.quantity)
                };
                return updatedCart;
            } else {
                // If the product doesn't exist, add it to the cart
                return [...prevCart, product];
            }
        });
    };


    const removeFromCart = (productId) => {
        setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
    };

    const clearCart = () => {
        setCart([]);
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};