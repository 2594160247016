// import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Api from '../../services/api.service';
import H1 from '../../components/Titles/h1';
import Link from '../../components/Links/link';

import Loading from '../../components/Loading/loading';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import FullWidthImage from '../../components/Images/image-full-width';

import Button from '../../components/Buttons/button';
import DescriptionSplit from '../../components/Description/description-split';
import TitlePage from '../../components/title-page';
import Select from '../../components/Inputs/select';
import H4 from '../../components/Titles/h4';
import H5 from '../../components/Titles/h5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useCart } from './../../contexts/cart-context';

export default function Product() {
    const { addToCart } = useCart();

    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState();
    const [quantity, setQuantity] = useState(1);

    const { slug } = useParams();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        Api.getProduct(slug).then((response) => {
            const res = response.data;
            if (res.status) {
                console.log(res);
                setProduct(res.data);
                setLoading(false);
            } else {
                console.log("error");
            }
        });
    }, [slug]);

    const numberOptions = () => {
        return Array.from({ length: product.modelProduct[0].quantity }, (_, index) => {
            return {
                value: `${index + 1}`,
                label: `${index + 1}`,
            };
        });
    };

    const handleSelectChangeQuantity = (event) => {
        setQuantity(event.target.value);
    };

    return (
        <div className='bg-backgroundColor'>
            {product && <Helmet>
                <title>L'Artbanne - {t('product.pageTitle')} - {product.name[i18n.language]}</title>
            </Helmet>}

            <div className='m-auto max-w-6xl w-5/6 pt-14'>
                <TitlePage title={t('product.welcomeMessage')} description={t('product.description')} />

                <div className=''>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className='flex gap-20 md:gap-40 py-12'>
                                <div className='w-1/3'>
                                    <FullWidthImage url={product.imageSrc} alt={t('product.alt') + product.name[i18n.language]} className='rounded-xl' />
                                </div>

                                <div className='w-2/3'>
                                    <H1 title={product.name[i18n.language]} />

                                    <div className='mt-8 flex justify-between items-center p-4 rounded-xl shadow'>
                                        <div>
                                            <span className='text-xl md:text-2xl font-bold text-main block'>
                                                {product.modelProduct[0].priceTTC + " EUR"}
                                            </span>
                                            <span className='text-sm md:text-md font-semibold text-borderColor line-through block'>
                                                {product.modelProduct[0].publicPriceTTC + " EUR"}
                                            </span>
                                        </div>

                                        <div>
                                            <Select label="quantity" options={numberOptions()}
                                                value={quantity} onChange={handleSelectChangeQuantity} displayLabel={false} />
                                        </div>
                                        <div>
                                            <Button text={t('inputs.addToCart')} onClick={() => addToCart({ slug: product.slug, reference: product.modelProduct[0].reference, quantity: quantity })} />
                                        </div>
                                    </div>

                                    <div className='mt-8'>
                                        <DescriptionSplit description={product.description[i18n.language]} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className='w-full flex justify-between'>
                    <H4 title={t('product.suggested')} fontWeight='bold' className='w-fit' />

                    <Link link="/" children={
                        <div className='flex gap-1 items-center'>
                            <H5 title={t('product.seeMore')} fontWeight='bold' theme='main' />
                            <FontAwesomeIcon icon={faChevronRight} className='text-main' />
                        </div>
                    } />
                </div>
            </div>
        </div>
    );
}