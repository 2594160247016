import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

import Api from '../../services/api.service';

import Loading from '../../components/Loading/loading';
// import LogoLong from '../../components/Logos/logo-long';

import { Helmet } from 'react-helmet';
import TitlePage from '../../components/title-page';
import SearchBar from '../../components/Inputs/searchbar';
// import H3 from '../../components/Titles/h3';
import H4 from '../../components/Titles/h4';
import DescriptionSplit from '../../components/Description/description-split';
import Link from '../../components/Links/link';
import H2 from '../../components/Titles/h2';
import CardProduct from './card-product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Shop() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    // const [errors, setErrors] = useState([{}]);
    const [errors] = useState([{}]);

    const { t } = useTranslation();

    useEffect(() => {
        // Simulate an API request for quotation data
        Api.getProducts().then((response) => {
            const res = response.data;
            if (res.status) {
                console.log(res);
                setProducts(res.data);
                setLoading(false);
            } else {
                console.log("error");
            }
        });
    }, []);

    // useEffect(() => {
    //     console.log(search);

    //     // let tmpErrors = {}
    //     // if (search.length && search.length < 3) {
    //     //     tmpErrors.searchBar = "length";
    //     // }

    //     // setErrors(tmpErrors);

    // }, [search]);


    const handleSearchBarChange = (e) => {
        const { value } = e.target;
        setSearch(value);
    };

    return (
        <div className='bg-backgroundColor'>
            <Helmet>
                <title>L'Artbanne - {t('shop.pageTitle')}</title>
            </Helmet>

            <div className='m-auto max-w-7xl w-5/6 pt-14'>
                <TitlePage title={t('shop.welcomeMessage')} description={t('shop.description')} />

                <div className='flex gap-20 py-12'>
                    <div className='w-1/4'>
                        <SearchBar label="findAProduct" name="findAProduct" value={search} error={errors.searchBar} onChange={handleSearchBarChange} />

                        <div className='mt-6'>
                            <H2 title={t('shop.categories.title')} fontWeight='semibold' />
                            TODO<br />
                            champ<br />
                            vin <br />
                        </div>

                        <div className='p-4 bg-lightgreen rounded-xl mt-6'>
                            <H4 title={t('shop.needHelp.title')} fontWeight='bold' />
                            <DescriptionSplit description={t('shop.needHelp.description')} className='md:text-sm' />

                            <H4 title={t('shop.needHelp.contactUs')} fontWeight='bold' className='mt-4' />

                            <div className='flex gap-2 my-2 text-sm items-center'>
                                <FontAwesomeIcon icon={faPhone} className='text-main' />
                                <Link link="tel:+33387885670" text="+33 (0)3 87 88 56 70" disableHoverEffect={true} />
                            </div>
                            <div className='flex gap-2 text-sm items-center'>
                                <FontAwesomeIcon icon={faEnvelope} className='text-main' />
                                <Link link="mailto:contact@lartbanne.com" text="contact@lartbanne.com" disableHoverEffect={true} />
                            </div>
                        </div>
                    </div>

                    <div className='w-3/4'>
                        {loading ? (
                            <Loading />
                        ) : (
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
                                {products.map((product) => (
                                    <div key={product.id}>
                                        <Link link={"/product/" + product.slug}>
                                            <CardProduct product={product} />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>


            </div>
        </div>
    );
}