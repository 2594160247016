import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from 'react-i18next';

export default function SearchBar({ label, name, value, onChange, onClear, error }) {
    const [focused, setFocused] = useState(false);
    console.log(value);

    const { t } = useTranslation();

    return (
        <div className="w-full relative">
            <div className="flex items-center relative">
                <input
                    type="text"
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    placeholder={t(`formInputs.${label}`)}
                    className={`w-full p-3 text-sm text-secondary bg-white border ${error ? "border-error" : "border-borderColor"
                        } rounded-md ${value || focused ? "pl-9" : "pr-8"} transition-all duration-300`}
                />
                {value && (
                    <button type="button" onClick={onClear} className="absolute top-0 right-0 m-3 transition-all duration-300">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                )}
                {!value && !focused && (
                    <span className={`absolute top-0 right-0 m-3 text-main transition-all duration-300`}>
                        <FontAwesomeIcon icon={faSearch} />
                    </span>
                )}
                {(value || focused) && (
                    <span className={`absolute top-0 left-0 m-3 text-main transition-all duration-300`}>
                        <FontAwesomeIcon icon={faSearch} />
                    </span>
                )}
            </div>

            {error && (
                <p className="mt-1 text-xs text-error ml-1 transition-all duration-300">{t(`errors.${error}`)}</p>
            )}
        </div>
    );
}      