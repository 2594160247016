import { useTranslation } from 'react-i18next';
import React from 'react';
import H6 from '../../components/Titles/h6';
import FullWidthImage from '../../components/Images/image-full-width';
import Select from '../../components/Inputs/select';

export default function CardProductCart({ product }) {
    const { t, i18n } = useTranslation();

    const numberOptions = () => {
        return Array.from({ length: product.modelProduct[0].quantity }, (_, index) => {
            return {
                value: `${index + 1}`,
                label: `${index + 1}`,
            };
        });
    };

    const handleSelectChangeQuantity = (event) => {
        // setQuantity(event.target.value);
        console.log(event);
    };

    return (
        <div className='text-center flex gap-3 px-2 py-4 cursor-pointer'>
            <FullWidthImage url={product.imageSrc} alt={t('shop.alt') + product.name[i18n.language]} className='rounded-xl' customHeight={150} customWidth={150} />

            <div className='flex flex-col justify-between'>
                <div className='flex flex-col gap-4 text-left'>
                    <span className='text-xl font-bold'>{product.modelProduct[0].priceTTC}€</span>
                    <H6 title={product.name[i18n.language]} fontWeight='light' />
                </div>

                <div className='w-fit text-center text-left'>
                    <Select label="quantity" options={numberOptions()}
                        value={product.modelProduct[0].quantity} onChange={handleSelectChangeQuantity} displayLabel={false} />
                </div>
            </div>
        </div>
    );
}