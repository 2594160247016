import React, { useEffect, useState, useMemo } from 'react';
import DescriptionSplit from '../../components/Description/description-split';
import Link from '../../components/Links/link';
import LogoLong from '../../components/Logos/logo-long';

import { useTranslation } from 'react-i18next';
import H2 from '../../components/Titles/h2';
import FullWidthImage from '../../components/Images/image-full-width';

export default function Footer() {
    const { t } = useTranslation();
    const [images, setImages] = useState([]);

    const cardsAccepted = useMemo(() => [
        { imgName: "cb", alt: "cb" },
        { imgName: "mastercard", alt: "mastercard" },
        { imgName: "maestro", alt: "maestro" },
        { imgName: "visa", alt: "visa" },
        { imgName: "electron", alt: "electron" },
        { imgName: "bleue", alt: "bleue" },
        { imgName: "jcb", alt: "jcb" }
    ], []); // Empty dependency array means the useMemo will only compute this array once


    useEffect(() => {
        const loadImages = async () => {
            const loadedImages = await Promise.all(
                cardsAccepted.map(async (card) => {
                    try {
                        const image = await import(`./../../assets/images/footer/${card.imgName}.png`);
                        return image.default;
                    } catch (error) {
                        console.error('Error loading image:', error);
                        return null;
                    }
                })
            );
            setImages(loadedImages.filter((image) => image !== null));
        };
        loadImages();
    }, [cardsAccepted]);


    return (
        <div className='w-full relative'>
            <div className='bg-secondary pt-28 pb-20'>
                <div className='mx-auto flex flex-wrap justify-between px-4 sm:px-6 lg:px-8'>
                    <div className='flex flex-col space-y-6 w-full md:w-1/3 lg:w-1/4 p-4'>
                        <LogoLong height={80} width={313} color='white' />
                        <div className='mt-6'>
                            <DescriptionSplit description={t('footer.description')} theme='light' />
                        </div>
                        <div className='mt-12'>
                            <DescriptionSplit description={t('footer.cardsAccepted')} theme='light' />
                            <div className='flex gap-2 mt-2'>
                                {cardsAccepted.map((card, index) => (
                                    <div key={card.imgName} className='w-fit'>
                                        <FullWidthImage url={images[index]} alt={card.alt} customHeight={30} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col space-y-6 w-full md:w-1/3 lg:w-1/4 p-4 text-center'>
                        <H2 title={t('footer.home')} theme='white' />

                        <div className={`flex flex-col items-center gap-6 pt-8`}>
                            <Link link="/domain" text={t('footer.domain')} textColor='white' />
                            <Link link="/services" text={t('footer.services')} textColor='white' />
                            <Link link="/history" text={t('footer.history')} textColor='white' />
                            <Link link="/infos" text={t('footer.infos')} textColor='white' />
                            <Link link="/contact" text={t('footer.contact')} textColor='white' />
                        </div>
                    </div>

                    <div className='flex flex-col space-y-6 w-full md:w-1/3 lg:w-1/4 p-4'>
                        <H2 title={t('footer.findUs')} theme='white' />
                        <div className='mt-8 mb-4'>
                            <p className='text-white mb-4'>
                                3 Rue de Betheniville, 51490
                                SAINT-HILAIRE-LE-PETIT - FRANCE
                            </p>
                            <p className='text-white mb-4'>
                                <Link link="tel:+33387885670" text="+ 33 (0)3 87 88 56 70" textColor='white' disableHoverEffect={true} />
                                <br />
                                <Link link="tel:+33677696100" text="+ 33 (0)6 77 69 61 00" textColor='white' disableHoverEffect={true} />
                            </p>
                            <p className='text-white'>
                                <Link link="mailto:contact@lartbanne.com" text="contact@lartbanne.com" textColor='white' disableHoverEffect={true} />
                            </p>
                        </div>

                        <div className=''>
                            faFacebookSquare
                            faInstagram
                            {/* <FontAwesomeIcon icon={faFacebookSquare} />
                        <FontAwesomeIcon icon={faInstagram} /> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-footer'>
                <div className='text-center text-white opacity-60 py-2'>
                    <span>
                        © 2023 - L'Artbanne&nbsp;|&nbsp;
                        <Link link="/domain" text={t('footer.legalNotices')} textColor='white' />&nbsp;|&nbsp;
                        <Link link="/domain" text={t('footer.personalData')} textColor='white' />&nbsp;|&nbsp;
                        <Link link="/domain" text={t('footer.generalConditions')} textColor='white' />&nbsp;|&nbsp;
                        <Link link="/domain" text={t('footer.cookieManagement')} textColor='white' />
                    </span>
                </div>
            </div>
        </div>
    );
}