import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { frShopPage, frProductPage, frCartPage, enShopPage, deShopPage, frInputs, frErrors, frResponses, frFooter, frFormInputs, frContact, frHeader } from "./utils/imports/import-translations";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: ["fr", "en-US", "de"],
        interpolation: {
            escapeValue: false
        },
        resources: {
            fr: {
                translation: {
                    shop: frShopPage,
                    product: frProductPage,
                    cart: frCartPage,
                    formInputs: frFormInputs,
                    inputs: frInputs,
                    errors: frErrors,
                    responses: frResponses,
                    header: frHeader,
                    footer: frFooter,
                    contact: frContact
                }
            },
            en: {
                translation: {
                    shop: enShopPage
                }
            },
            de: {
                translation: {
                    shop: deShopPage
                }
            }
        }
    });

export default i18n;