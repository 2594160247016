import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import H6 from '../../components/Titles/h6';
import FullWidthImage from '../../components/Images/image-full-width';
import ButtonSecondary from '../../components/Buttons/button-secondary';

export default function CardProduct({ product }) {
    const [minimalPrice, setMinimalPrice] = useState(0);
    const [displayPriceFrom, setDisplayPriceFrom] = useState(false);

    const { t, i18n } = useTranslation();

    console.log(i18n.language);
    console.log(product);

    useEffect(() => {
        for (const model of product.modelProduct) {
            let tmpMinimalPrice;

            if (!tmpMinimalPrice || model.priceTTC < tmpMinimalPrice) {
                tmpMinimalPrice = model.priceTTC;
            }

            if (tmpMinimalPrice && model.priceTTC !== tmpMinimalPrice) {
                setDisplayPriceFrom(true);
            }

            setMinimalPrice(tmpMinimalPrice);
        }
    }, [product]);

    return (
        <div className='bg-backgroundColor shadow rounded-xl text-center flex flex-col gap-3 px-2 py-4 cursor-pointer'>
            <FullWidthImage url={product.imageSrc} alt={t('shop.alt') + product.name[i18n.language]} className='rounded-xl' />

            <H6 title={product.name[i18n.language]} />

            <div>
                {displayPriceFrom && <span>{t('shop.form')}</span>}
                <H6 title={minimalPrice + "EUR"} fontWeight='bold' />
            </div>

            <div className='w-fit text-center m-auto'>
                <ButtonSecondary text={t('inputs.addToCart')} />
            </div>
        </div>
    );
}