import './App.scss';
import Shop from './containers/Shop/shop';

function App() {

  return (
    <div className="App">
      <Shop />
    </div>
  );
}

export default App;