import React from 'react';
import Link from '../../components/Links/link';
import LogoLong from '../../components/Logos/logo-long';
import I18nComponentChooseLanguage from '../../components/i18n-choose-language';


export default function Header() {
    return (
        <header className="bg-backgroundColor flex justify-center pt-8">
            <Link link={"/"}>
                <LogoLong width={250} height={64} />
            </Link>

            <div className='hidden w-24 md:absolute md:flex top-4 right-4 items-center'>
                <I18nComponentChooseLanguage />
            </div>
        </header>
    );
}
