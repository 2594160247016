import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import './i18n';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from './containers/Layout/layout';
import ErrorPage from "./containers/Error/error-page";
import Product from './containers/Product/product';
import Cart from './containers/Cart/cart';
import { CartProvider } from './contexts/cart-context';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout children={<App />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/product/:slug",
    element: <Layout children={<Product />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cart",
    element: <Layout children={<Cart />} />,
    errorElement: <ErrorPage />,
  }

]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <CartProvider>
      <RouterProvider router={router} />
      <ToastContainer />
    </CartProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
